import React from 'react'
import { graphql} from 'gatsby'
import * as PropTypes from 'prop-types'
import Layout from '../layouts'

const propTypes = {
    data: PropTypes.object.isRequired,
}

class PostTemplate extends React.Component {

    render() {
        const pageData = this.props.data.contentfulPost
        const homeData = this.props.data.contentfulPost
        const isHome = pageData.id === '7b820263-73d9-5083-a447-6c9810977c33';
        const seo = pageData.seo || homeData.seo;

        const data = {
            ...this.props,
            pageData,
            homeData,
            isHome,
            seo,
        };

        return (
            <Layout {...data} />
        )
    }
}

PostTemplate.propTypes = propTypes

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
      contentfulPost(id: {eq: $id}) {
        isKnowledgeBaseArticle
        contentful_id
        slug
        featuredImage {
          file {
            url
          }
          title
        }
        categories
        childContentfulPostPostContentTextNode {
          childMarkdownRemark {
            html
          }
        }
        title
        publishedOn
        seo {
          metaDescription {
            metaDescription
          }
          focusKeyPhrase
          metaImage {
            description
            file {
              url
            }
            title
          }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
  }
`

